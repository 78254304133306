<template>
  <div>
    <form class="consultation-form" @submit.prevent="send">
      <div class="uk-flex uk-flex-wrap">
        <div class="uk-width-1-1@s uk-width-1-2@m uk-flex uk-flex-column uk-flex-middle">
          <img :data-src="picture" data-width data-height alt="" uk-img>
          <div class="uk-width-1-1 uk-text-center consultation-form__title">Лариса Кокорина</div>
          <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom uk-margin-meduim-top">
            руководитель отдела продаж
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-2@m uk-flex uk-flex-column uk-flex-center uk-flex-middle">
          <div class="uk-width-1-1 uk-text-center consultation-form__title">{{ title }}</div>
          <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom uk-margin-meduim-top">
            {{ subtitle }}
          </div>
          <div class="uk-width-1-1 uk-margin-meduim-bottom uk-flex uk-flex-center">
            <div class="consultation-form__phone uk-text-center fs18 fs26@m">              
            </div>
          </div>
          <div v-if="isSent" class="uk-margin-medium-top uk-margin-medium-bottom">Ваш запрос отправлен специалисту</div>
          <div v-else class="uk-width-1-1 uk-flex uk-flex-wrap uk-child-width-1-1@s uk-child-width-1-2@m uk-grid">
            <div>
              <input
                class="uk-input uk-margin-small-top"
                type="text"
                placeholder="Ваше имя"
                v-model="inputs.name"
              />
            </div>
            <div>
              <input
                class="uk-input uk-margin-small-top"
                type="text"
                placeholder="Ваш телефон"
                v-model="inputs.phone"
              />
            </div>
          </div>
          <div class="uk-width-1-1 uk-text-center">
            <div class="politica">
              Отправляя заявку, вы соглашаетесь на обработку
              <a :href="politika" target="_blank">персональных данных</a>
            </div>
          </div>
          <div class="uk-width-1-1 uk-text-right uk-flex uk-flex-center">
            <button class="btn btn__tpl1 btn__green btn__square uk-margin-medium-bottom">
              Получить консультацию
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import form from "../mixins/Form.js";
import input from "../filters/Input.js";
import inputMask from "../mixins/inputMask";

export default {
  name: "consultation",
  mixins: [form, input, inputMask],
  props: {
    title: {
      type: String,
      default: "Получите бесплатную консультацию по подбору земельного участка",
    },
    subtitle: {
      type: String,
      default: "",
    },
    politika: {
      type: String,
      default: "/wp-content/themes/agentstvo/public/politika.pdf",
    },
    staffId: {
      type: Number,
    },
    managerId: {
      type: Number,
    },
    classId: {
      type: Number,
    },
    sourceId: {
      type: Number,
      default: 34
    },
    picture: {
      type: String,
      default: "https://vevanta.ru/wp-content/uploads/2020/12/larisa.png"
    }
  },

  methods: {
    send() {
      this.formName = this.title;

      if (this.staffId !== undefined) {
        this.dataRequest.staffId = this.staffId;
      }

      if (this.managerId !== undefined) {
        this.dataRequest.managerId = this.managerId;
      }

      if (this.classId !== undefined) {
        this.dataRequest.classId = this.classId;
      }

      if (this.sourceId !== undefined) {
        //lead.sourceId = this.sourceId;
      }

      this.sendForm();
    },
  },
};
</script>

<style lang="css" scoped>
.consultation-form__title {
  font-family: Gilroy-Bold;
  font-size: 28px;
  color: #333;
  margin-top: 15px;
}

.consultation-form {
  box-shadow: 0 0 42px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  padding: 30px 34px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.consultation-form__subtitle {
  font-family: Gilroy-Regular;
}
.consultation-form__block {
  margin-top: 20px;
}

.consultation-form__phone a {
  color: #212121;
  font-family: Gilroy-Semibold;
  text-decoration: none;
}

.consultation-form__subtitle,
.consultation-form__phone,
.consultation-form__input {
  margin-right: 20px;
}

.consultation-form__input {
  width: 33%;
}

.btn__square {
  border-radius: 0;
}

.input__padding {
  padding: 0px;
}

@media screen and (max-width: 1200px) {
  .consultation-form__input {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 580px) {
  .consultation-form__title {
    font-size: 21px;
  }

  .input__padding {
    padding-left: 64px;
  }
}
</style>
