<template>
  <div>
    <div id="map" class="uk-width-1-1 uk-height-large"></div>
  </div>
</template>

<script>
export default {

  data(){
    return {
      map: null,
      coords: [
        [57.079395, 65.262322],
        [57.081763, 65.264575],
        [57.081576, 65.267279],
        [57.081588, 65.269124],
        [57.082194, 65.272815],
        [57.080037, 65.274618],
        [57.079454, 65.276377],
        [57.079232, 65.276506],
        [57.079209, 65.276484],
        [57.078999, 65.276356],
        [57.078812, 65.276463],
        [57.078637, 65.276677],
        [57.078579, 65.27715],
        [57.078346, 65.277321],
        [57.078253, 65.277343],
        [57.078089, 65.277085],
        [57.078008, 65.275712],
        [57.078031, 65.272622],
        [57.078567, 65.272021],
        [57.078801, 65.271926],
        [57.078917, 65.27169],
        [57.07895, 65.271374],
        [57.079246, 65.270352],
        [57.079324, 65.269295,],
        [57.07939, 65.267713],
        [57.079536, 65.267501],
        [57.079704, 65.267424],
        [57.079777, 65.267207],
        [57.079756, 65.266946],
        [57.079825, 65.264981],
        [57.079595, 65.264496],
        [57.079399, 65.263961],
        [57.079345, 65.262841],
        [57.079413, 65.262505]
      ],
      polygon: null,
      markers: null,
      coordinates: [
        [57.084862, 65.226391],
        [57.084883, 65.228762],
        [57.083129, 65.228198],
        [57.083395, 65.229511],
        [57.085119, 65.233484],
        [57.092923, 65.265766],
        [57.086549, 65.264293],
        [57.083113, 65.228172],
        [57.084403, 65.232995]
      ],
      popups: [
        'Переваловская средняя общеобразовательная школа Тюменского муниципального района',
        'Детский сад',
        'Пятерочка',
        'Магнит',
        'Красное & Белое',
        'Вената',
        'Перекресток',
        'Фармакопейка',
        'Фарма-Грант'
      ]
    }
  },

  methods: {
    startMap: function () {
        DG.then(() => {
         this.map = DG.map('map', {
             center: [57.086975, 65.273223],
             zoom: 14
         });
         DG.popup([57.080821, 65.269571])
           .setLatLng([57.080821, 65.269571])
           .setContent('Коттеджный поселок Веванта Life')
           .openOn(this.map);
         this.polygon = DG.polygon(this.coords, {color: "red"}).addTo(this.map);

         this.markers = DG.featureGroup();

         for(let i = 0; i < 9; i++) {
           DG.marker(this.coordinates[i]).addTo(this.markers).bindPopup(this.popups[i])
         }

         this.markers.addTo(this.map)
       });
     }
  },

  created() {
    let script = document.createElement('script')
     script.setAttribute('src', 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full')
     document.head.appendChild(script)
     script.onload = () => {
       this.startMap()
     }
  }

}


</script>
