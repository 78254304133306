<template>
  <div
    class="uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-1"
    uk-grid
  >
    <div v-for="(item, i) in items" :key="i">
      <div class="uk-card uk-card-default">
        <div
          class="uk-card-media-top uk-position-relative"
          tabindex="-1"
          uk-slideshow
        >
          <ul class="uk-slideshow-items">
            <li v-for="(ph, index) in item.img" :key="index">
              <a :href="item.url" target="_blank"
                ><img :src="`${cdn}${ph}`" alt="" uk-cover
              /></a>
            </li>
          </ul>
          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slideshow-item="previous"
            style="color:#fff;"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slideshow-item="next"
            style="color:#fff;"
          ></a>
        </div>
        <a
          class="uk-display-block uk-card-body uk-link-toggle"
          :href="item.url"
          target="_blank"
          ><div class="uk-flex uk-flex-column ">
            <div>
              <img src="https://vevanta.ru/wp-content/uploads/2020/12/house_icon.png" style="filter: contrast(0.1)" class="uk-margin-small-right uk-margin-small-bottom" height="20px" width="20px">
                {{ item.title }}
            </div>
            <div>
              <img src="https://vevanta.ru/wp-content/uploads/2020/12/plan_icon.png" style="filter: contrast(0.1)" class="uk-margin-small-right" height="20px" width="20px">
              {{ item.square }} м<sup>2</sup>
              <span v-if="item.second_square">
                + {{ item.second_square }} м<sup>2</sup></span
              >
            </div>
            <div
              class="btn btn__tpl1 btn__green uk-width-1-1 uk-text-center uk-margin-medium-top btn__square"
              uk-toggle="target: #call-modal2"
            >
              Узнать стоимость
            </div>
          </div>
        </a>
      </div>
    </div>

    <div id="call-modal2" uk-modal ref="modal_call2">
      <div class="uk-modal-dialog uk-modal-body uk-width-1-1@s uk-width-1-4@m">
        <h5 class="uk-modal-title uk-margin-remove ">
          Заявка на звонок
        </h5>
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <form class="uk-margin-small-top">
          <div>
            <label class="uk-form-label" for="name">ФИО</label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                :class="{ 'uk-form-danger': invalidName }"
                v-model="name"
                id="name"
                type="text"
                placeholder="ФИО"
              />
            </div>
          </div>
          <div>
            <label class="uk-form-label" for="phone">Телефон</label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                :class="{ 'uk-form-danger': invalidPhone }"
                v-model="phone"
                id="phone"
                type="tel"
                placeholder="(999) 999-99-99"
              />
            </div>
          </div>
        </form>
        <div v-if="isSend" class="uk-alert-success" uk-alert>
          <p>
            Заявка успешно отправлена. В ближайшее время с вами свяжется наш
            менеджер
          </p>
        </div>
        <p class="uk-text-center">
          <a class="uk-button uk-button-primary" @click="sendForm">
            Отправить
          </a>
        </p>
      </div>
    </div>
    \
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  data() {
    return {
      name: '',
      phone: '',
      isSend: false,
      cdn: "https://vevanta.ru/wp-content/uploads/2020/houses/",
      items: [
        {
          title: "Елена",
          img: ["elena-1.jpg", "elena-2.jpg", "elena-3.jpg", "elena-plan.jpg"],
          url: "https://vevanta.com/novye-proekty/proekt-doma-elena",
          square: 126
        },
        {
          title: "Тюменка",
          img: [
            "tyumenka-1.jpg",
            "tyumenka-2.jpg",
            "tyumenka-3.jpg",
            "tyumenka-plan.jpg"
          ],
          url: "https://vevanta.com/novye-proekty/proekt-doma-tjumenka",
          square: 156.1
        },
        {
          title: "Хай-Тек",
          img: [
            "hitek-1.jpg",
            "hitek-2.jpg",
            "hitek-3.jpg",
            "hitek-plan-1.jpg",
            "hitek-plan-2.jpg"
          ],
          url:
            "https://vevanta.com/novye-proekty/ultrasovremennyj-proekt-doma-haj-tek",
          square: 239.12,
          second_square: 71.55
        },
        {
          title: "Даймонд",
          img: [
            "diamond-1.jpg",
            "diamond-2.jpg",
            "diamond-3.jpg",
            "diamond-plan.jpg"
          ],
          url: "https://vevanta.com/novye-proekty/proekt-dajmond",
          square: 271.03,
          second_square: 25.53
        }
      ]
    };
  },
  methods: {
    sendForm() {
     this.$axios
        .post('https://life.workrf.ru/send.php', {
          name: this.name,
          phone: this.phone,
        })
        .then(() => {
          this.name = "";
          this.phone = "";
          UIkit.modal(this.$refs.modal_call2).hide();
        })
        .catch(error => {
          console.log(error);
        });
    },
  }
};
</script>

<style scoped>
.btn__square {
  border-radius: 0;
}

</style>
