<template>
  <section class="footer bg-wblue-2">
    <footer class="uk-container uk-padding-small">


      <div class="cl-dark-grey uk-margin-medium-top uk-margin-bottom">
        © 2009-2021, г. Тюмень, ул. Московский тракт, д. 165/1 Строительная
        компания «Веванта» ИНН 7203380262, ОГРН 1167232063602
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  data() {
    return {
      agency: "",
      phone: "",
      email: "",
      isValid: true,
    };
  },
  watch: {
    phone(val) {
      if (val.startsWith("8")) this.phone = this.phone.substring(1);
      else if (val.startsWith("+7")) this.phone = this.phone.substring(2);
      this.phone = this.phone.replace(/[\s-()]/gi, "");

      if (this.phone.length > 10) {
        this.phone = this.phone.slice(0, 10);
      }
    },
  },
  computed: {
    validForm() {
      return !(!this.email || !this.agency || !this.phone);
    },
  },
  methods: {
    subscribe() {
      if (!this.validForm) {
        this.isValid = false;
        return;
      }
      this.isValid = true;
      this.$axios
        .post("https://life.workrf.ru/send.php", {
          form: "Подписка на рассылку",
          name: this.agency,
          phone: this.phone,
          email: this.email,
          url: location.href,
          msg: "",
          fields: {
            status_id: 32608795,
            pipeline_id: 3208921,
            "698747": true,
          },
        })
        .then(() => {
          this.UIkit.modal("#success-modal").show();
          this.name = "";
          this.phone = "";
          this.email = "";
        });
    },
  },
};
</script>

<style>
.footer-background {
  background-image: url("../assets/img/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.footer__form-conatin {
  background-color: #fff;
}

.footer__footer-title {
  font-size: 23px;
}
</style>
