<template>
  <div uk-slider class="uk-margin-medium-top">
      <div class="uk-position-relative">
          <div class="uk-slider-container uk-light">
              <ul class="uk-slider-items uk-child-width-1-6@m uk-child-width-1-3@s uk-grid" >
                  <li v-for="advantage in advantages" :key="advantage.id">
                      <a href="#">
                        <img :src="`${cdn2}${advantage.img}`" alt="">
                        <div class="uk-panel uk-position-top-left uk-padding-small uk-margin-medium-left uk-margin-medium-right cl-dark">{{advantage.text}}</div>
                      </a>
                  </li>
              </ul>
          </div>
          <div class="uk-hidden@s uk-light">
              <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
              <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
          </div>
          <div class="uk-visible@s">
              <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
              <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "common",
    data() {
      return {
        cdn2: "https://vevanta.ru/wp-content/themes/agentstvo/assets/images/home/",
        advantages: [
          {
            img: "advantage_1.jpg",
            text: "Участие в Веванта CLUB",
            link: "#"
          },
          {
            img: "advantage_2.jpg",
            text: "Купите квартиру за материнский капитал",
            link: "#"
          },
          {
            img: "advantage_3.jpg",
            text: "Оценка рыночной стоимости жилья",
            link: "#"
          },
          {
            img: "advantage_4.jpg",
            text: "Ищем опытных риелторов",
            link: "https://vevanta.com/vakansii"
          },
          {
            img: "advantage_5.jpg",
            text: "Поможем переехать в собственный дом",
            link: "https://vevanta.com/arhitekturnoe-proektirovanie-zhilyh-domov-chto-nuzhno-znat-zakazchiku"
          },
          {
            img: "advantage_6.jpg",
            text: "Подберем выгодную ипотеку",
            link: "#"
          },
          {
            img: "advantage_7.jpg",
            text: "Акции, скидки, бонусы только для наших клиентов",
            link: "https://vevanta.com/category/special"
          },
          {
            img: "advantage_8.jpg",
            text: "Ответим на Ваши вопросы он-лайн",
            link: "https://vevanta.com/kontakty"
          },
          {
            img: "advantage_9.jpg",
            text: "Гарантируем юридическую безопасность",
            link: "#"
          },
          {
            img: "advantage_10.jpg",
            text: "Покупка новостройки без комиссии",
            link: "#"
          },
          {
            img: "advantage_11.jpg",
            text: "Рассчитать ипотеку он-лайн",
            link: "#"
          }
        ],
      }
    }
  }
</script>
