import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import UIkit from 'uikit'


Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/'
 })
Vue.prototype.UIkit = UIkit;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
