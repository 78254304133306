<template>
  <div class="uk-container-expand">
    <div class="uk-container">
      <nav class="uk-navbar uk-navbar-container uk-hidden@m uk-background-muted uk-position-fixed uk-position-top uk-width-1-1 nav-mob">
        <div class="uk-navbar-left uk-margin-small-left">
          <a href="https://vevanta.com"
            ><img src="../assets/img/logo.png" width="150px"
          /></a>
        </div>
      </nav>

      <div class="uk-visible@m">
        <div class="uk-container uk-margin-remove" uk-grid>
          <div class="uk-width-expand uk-padding-remove">
            <a href="https://vevanta.com"
              ><img src="../assets/img/logo.png"
            /></a>
          </div>
          <div class="uk-margin-auto-vertical">
            Г. Тюмень, ул Московский тракт д 165/1<br />
            <b>Режим работы:</b><br />
            Пн-Сб 09:00-19:00
          </div>

          <div class="uk-margin-auto-vertical">
            <div class="uk-margin-small-bottom">
            </div>
              <button class="btn btn__green" uk-toggle="target: #call-modal">
                  Заказать звонок
              </button>
          </div>
          <div
            class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6 logos"
          >
            <p class="uk-margin-small">
              <a
                href="https://vk.com/stroitelstvo_domov_i_kottedjey"
                target="_blank"
                ><img
                  src="../assets/img/vkontakte.png"
                  height="20px"
                  width="20px"
              /></a>
            </p>
            <p class="uk-margin-small">
              <a href="https://www.instagram.com/vevanta72" target="_blank"
                ><img
                  src="../assets/img/instagram.png"
                  height="20px"
                  width="20px"
              /></a>
            </p>
            <p class="uk-margin-small">
              <a
                href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
                target="_blank"
                ><img src="../assets/img/youtube.png" height="20px" width="20px"
              /></a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="call-modal" uk-modal ref="modal_call">
      <div class="uk-modal-dialog uk-modal-body uk-width-1-1@s uk-width-1-4@m">
        <h5 class="uk-modal-title uk-margin-remove ">
          Заявка на звонок
        </h5>
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <form class="uk-margin-small-top">
          <div>
            <label class="uk-form-label" for="name">ФИО</label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                :class="{ 'uk-form-danger': invalidName }"
                v-model="name"
                id="name"
                type="text"
                placeholder="ФИО"
              />
            </div>
          </div>
          <div>
            <label class="uk-form-label" for="phone">Телефон</label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                :class="{ 'uk-form-danger': invalidPhone }"
                v-model="phone"
                id="phone"
                type="tel"
                placeholder="(999) 999-99-99"
              />
            </div>
          </div>
        </form>
        <div v-if="isSend" class="uk-alert-success" uk-alert>
          <p>
            Заявка успешно отправлена. В ближайшее время с вами свяжется наш
            менеджер
          </p>
        </div>
        <p class="uk-text-center">
          <a class="uk-button uk-button-primary" @click="sendForm">
            Отправить
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  data() {
    return {
      name: '',
      phone: '',
      formatPhone: '',
      invalidPhone: false,
      invalidName: false,
      isSend: false
    };
  },
  watch: {
    phone(val) {
      let currentPhone = '';
      this.formatPhone = val.replace(/[\s-()+]/gi, "");
      if (this.formatPhone.length >= 0) {
        if (this.formatPhone.startsWith('7') || this.formatPhone.startsWith('8')) this.formatPhone = this.formatPhone.slice(1);
        currentPhone += '+7';
      }
      if (this.formatPhone.length >= 1) {
        currentPhone += '(';
        currentPhone += this.formatPhone.slice(0, 3);
      }
      if (this.formatPhone.length > 3) {
        currentPhone += ') ';
        currentPhone += this.formatPhone.slice(3, 6);
      }
      if (this.formatPhone.length > 6) {
        currentPhone += '-';
        currentPhone += this.formatPhone.slice(6, 8);
      }
      if (this.formatPhone.length > 8) {
        currentPhone += '-';
        currentPhone += this.formatPhone.slice(8, 10);
      }
      if (this.formatPhone.length == 10) {
        this.phone = this.phone.slice(0, 10);
      }
      this.phone = currentPhone;
    }
  },
  methods: {
    sendForm() {
     this.$axios
        .post('https://life.workrf.ru/send.php', {
          name: this.name,
          phone: this.phone,
        })
        .then(() => {
          this.name = "";
          this.phone = "";
          UIkit.modal(this.$refs.modal_call).hide();
        })
        .catch(error => {
          console.log(error);
        });
    },
  }
};
</script>

<style scoped>
.uk-subnav li a {
  font-size: 0.75em !important;
}
.uk-subnav > * > :first-child {
  color: #000 !important;
}
.nav-top {
  padding-right: 0 !important;
}

.nav-top a {
  font-size: 11px;
}

.uk-navbar-nav.nav-bottom li:first-child a {
  padding-left: 0 !important;
}

.uk-navbar-nav.nav-bottom a {
  font-weight: bold;
}

.logos a {
  opacity: 0.9;
  filter: grayscale(1);
  transition: filter 0.3s, opacity 0.3s;
}

#mobile-nav p,
#mobile-nav li,
#mobile-nav a,
hr {
  color: #666;
  border-color: #666;
}

.nav-mob {
  position: absolute;
  top: 0px;
  z-index: 4;
}
</style>
