<template>
  <div class="contain uk-margin-large-top">
    <nav
      class="uk-background-default uk-flex uk-flex-wrap uk-width-1-1 uk-child-width-1-1 uk-child-width-auto@m"
    >
      <div class="place-params uk-position-relative">
        <button class="uk-button uk-button-default uk-width-1-1" type="button">
          Площадь участка:
          <span v-if="minArea"> от {{ minArea }}</span>
          <span v-if="maxArea"> до {{ maxArea }}</span>
          сот.
        </button>
        <div
          uk-drop="mode: click; pos: bottom-left; offset: 0"
          class="uk-width-1-1"
        >
          <div class="uk-card uk-card-body uk-card-default">
            <div class="uk-margin">
              <div class="uk-flex uk-space-between">
                <div class="uk-margin-small-right">
                  <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip">сот.</span>
                    <input
                      class="uk-input uk-form-small"
                      type="text"
                      placeholder="От"
                      v-model.number.lazy="minArea"
                    />
                  </div>
                </div>
                <div>
                  <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip">сот.</span>
                    <input
                      class="uk-input  uk-form-small"
                      type="text"
                      placeholder="До"
                      v-model.number.lazy="maxArea"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="place-params uk-position-relative">
        <button class="uk-button uk-button-default uk-width-1-1" type="button">
          Стоимость участка:
          <span v-if="minPrice"> от {{ minPrice | price }}</span>
          <span v-if="maxPrice"> до {{ maxPrice | price}}</span>
          ₽
        </button>
        <div
          uk-drop="mode: click; pos: bottom-left; offset: 0"
          class="uk-width-1-1"
        >
          <div class="uk-card uk-card-body uk-card-default">
            <div class="uk-margin">
              <div class="uk-flex uk-space-between">
                <div class="uk-margin-small-right">
                  <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip">₽</span>
                    <input
                      class="uk-input uk-form-small"
                      type="text"
                      placeholder="От"
                      v-model.number.lazy="minPrice"
                    />
                  </div>
                </div>
                <div>
                  <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip">₽</span>
                    <input
                      class="uk-input  uk-form-small"
                      type="text"
                      placeholder="До"
                      v-model.number.lazy="maxPrice"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="place-status uk-position-relative">
        <button class="uk-button uk-button-default uk-width-1-1" type="button">
          Статус участка
        </button>
        <div uk-dropdown="mode: click; offset: 0" class="uk-width-1-1">
          <ul class="uk-nav uk-dropdown-nav">
            <li
              @click="statusFilter = 'all'"
              :class="{ selected: statusFilter == 'all' }"
            >
              Все участки
            </li>
            <li
              v-for="(status, id) in statuses"
              :key="status"
              @click="statusFilter = id"
              :class="{ selected: statusFilter == id }"
            >
              {{ status }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="uk-margin-top">
      <div class="map">
        <button
          class="scale-button z-index-10 fs28 uk-text-secondary"
          @click="scale = 1.5"
        >
          <span>+</span>
        </button>
        <button
          class="scale-button z-index-10 fs28 uk-text-secondary"
          @click="scale = 1"
        >
          <span>–</span>
        </button>
        <div v-dragscroll.pass ref="wrapper" id="wrapper" v-show="!!bgimage">
          <div ref="image" id="image" :style="{ transform: `scale(${scale})` }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.2"
              baseProfile="tiny"
              id="svg"
              class="svg-pan-zoom_viewport"
            >
              <image
                ref="image"
                :xlink:href="getBgPath"
                x="0"
                y="0"
                height="100%"
                width="100%"
              />
              <g
                id="viewport"
                class="svg-area"
                v-for="object in getFilterObjects"
                :key="object.id"
                @click="selectedPlace = object"
              >
                <polygon
                  :points="object.points"
                  :class="selectClasses(object)"
                />
                <circle
                  :cx="centroid(object.points).x - 1"
                  :cy="centroid(object.points).y - 4"
                  r="8"
                />
                <text
                  class="area-id"
                  :x="
                    centroid(object.points).x - object.id.toString().length * 3
                  "
                  :y="centroid(object.points).y"
                >
                  {{ object.id }}
                </text>
                <text
                  class="area-land"
                  :x="centroid(object.points).x - 10"
                  :y="centroid(object.points).y + 12"
                >
                  {{ object.land_area }} сот.
                </text>
              </g>
            </svg>
          </div>
        </div>

        <transition
          enter-active-class="uk-animation-slide-right-medium"
          leave-active-class="uk-animation-slide-right-medium uk-animation-reverse"
        >
          <div
            class="reservation-card uk-card uk-card-default uk-card-small uk-card-body uk-width-1-1@s uk-width-1-3@m"
            v-show="!!selectedPlace"
          >
            <span
              class="close"
              uk-icon="icon: close"
              @click="selectedPlace = ''"
            ></span>
            <h3 class="uk-card-title uk-margin-remove">
              Участок {{ selectedPlace.name }}
            </h3>
            <p
              class="uk-margin-small-top uk-margin-remove-bottom"
              style="font-size: 12px;"
            >
              Кадастровый номер
            </p>
            <p class="uk-margin-remove" style="position: relative">
              <a :href="getLink" target="_blank">{{
                selectedPlace.cadastral_number
              }}</a>
            </p>
            <hr class="uk-width-1-1" />
            <div
              class="uk-card-body uk-padding-remove uk-margin-remove uk-grid-small uk-width-1-1"
              uk-grid
            >
              <div
                class="uk-grid uk-first-column uk-padding-remove uk-margin-remove stroke"
                v-if="selectedPlace.status_id == 1"
              >
                <div
                  class="uk-width-1-2@m uk-width-1-1@s uk-text-left uk-padding-remove"
                >
                  <p>Стоимость</p>
                </div>
                <div
                  class="cart-info uk-width-1-2@m uk-width-1-1@s uk-text-right"
                >
                  <p>{{ selectedPlace.price }} руб.</p>
                </div>
              </div>
              <div
                class="uk-grid uk-first-column uk-padding-remove uk-margin-remove stroke"
              >
                <div
                  class="uk-width-1-2@m uk-width-1-1@s uk-text-left uk-padding-remove"
                >
                  <p>Площадь</p>
                </div>
                <div
                  class="cart-info uk-width-1-2@m uk-width-1-1@s uk-text-right"
                >
                  <p>{{ selectedPlace.land_area }} {{ getAreaText }}</p>
                </div>
              </div>
              <div
                class="uk-grid uk-first-column uk-padding-remove uk-margin-remove stroke"
              >
                <div
                  class="uk-width-1-2@m uk-width-1-1@s uk-text-left uk-padding-remove"
                >
                  <p>Статус</p>
                </div>
                <div
                  class="cart-info uk-width-1-2@m uk-width-1-1@s uk-text-right"
                  :class="selectClasses(selectedPlace)"
                >
                  <p>{{ statuses[selectedPlace.status_id] }}</p>
                </div>
              </div>
              <div
                class="uk-margin-small-top uk-margin-small-bottom uk-width-1-1 uk-flex uk-flex-between uk-padding-remove"
              >
                <font-awesome-icon icon="road" uk-tooltip="Дорога" />
                <font-awesome-icon icon="burn" uk-tooltip="Газ" />
                <font-awesome-icon icon="bolt" uk-tooltip="Электричество" />
                <font-awesome-icon icon="wifi" uk-tooltip="Интернет" />
              </div>
              <div
                class="uk-margin-small-top uk-flex uk-flex-between uk-padding-remove"
              >
                <button
                  class="uk-button uk-button-primary modal-button uk-button-small uk-width-1-1"
                  uk-toggle="target: #reserved-modal"
                  :disabled="selectedPlace.status_id != 1"
                >
                  Заявка на бронь
                </button>

                <button
                  class="uk-button uk-button-default modal-button uk-button-small uk-width-1-1"
                  uk-toggle="target: #consultation-modal"
                >
                  Консультация
                </button>
              </div>
              <div
                class="uk-width-1-1 uk-flex uk-flex-center uk-margin-small uk-padding-remove"
              >
                <p>
                  Приобретая данный участок, вы можете заказать постройку одного
                  из наших домов
                </p>
              </div>
              <div
                class="uk-width-1-1 uk-flex uk-flex-center uk-margin-small uk-padding-remove"
              >
                <ul uk-tab>
                  <li
                    v-for="(house, i) in houses"
                    :key="i"
                    class="uk-padding-remove"
                  >
                    <a @click="selectedHouse = house">{{ house.title }}</a>
                  </li>
                </ul>
              </div>
              <div
                class="uk-width-1-1 uk-flex uk-flex-center uk-margin-small uk-padding-remove"
              >
                <div class="uk-position-relative uk-visible-toggle" uk-slider>
                  <ul class="uk-slider-items uk-child-width-1-1">
                    <li
                      v-for="(imgSrc, i) in selectedHouse
                        ? selectedHouse.img
                        : houses[0].img"
                      :key="i"
                    >
                      <a href="#carousel-modal" target="_blank" uk-toggle>
                        <img
                          :src="
                            `https://vevanta.ru/wp-content/uploads/2020/houses/${imgSrc}`
                          "
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <a
                    class="uk-position-center-left uk-position-small navigation"
                    href="#"
                    uk-slidenav-previous
                    uk-slider-item="previous"
                  ></a>
                  <a
                    class="uk-position-center-right uk-position-small navigation"
                    href="#"
                    uk-slidenav-next
                    uk-slider-item="next"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div id="reserved-modal" uk-modal ref="modal">
          <div
            class="uk-modal-dialog uk-modal-body uk-width-1-1@s uk-width-1-4@m"
          >
            <h2
              class="uk-modal-title uk-margin-small-top uk-margin-remove-bottom"
            >
              Заявка на бронирование
            </h2>
            <form>
              <div class="uk-margin">
                <label class="uk-form-label" for="name">ФИО</label>
                <div class="uk-form-controls">
                  <input
                    class="uk-input"
                    :class="{ 'uk-form-danger': invalidName }"
                    v-model="name"
                    id="name"
                    type="text"
                    placeholder="Фамилия Имя"
                  />
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="phone">Телефон</label>
                <div class="uk-form-controls">
                  <input
                    class="uk-input"
                    :class="{ 'uk-form-danger': invalidPhone }"
                    v-model="phone"
                    id="phone"
                    type="tel"
                    placeholder="(999) 999-99-99"
                  />
                </div>
              </div>
            </form>
            <div v-if="isSend" class="uk-alert-success" uk-alert>
              <p>
                Заявка успешно отправлена. В ближайшее время с вами свяжется наш
                менеджер
              </p>
            </div>
            <p class="uk-text-center">
              <a
                class="uk-button uk-button-primary"
                @click="reservationPlace(selectedPlace.id, name, phone)"
              >
                Отправить заявку
              </a>
              <button
                class="uk-button uk-button-default uk-modal-close"
                type="button"
              >
                Закрыть
              </button>
            </p>
          </div>
        </div>

        <div id="consultation-modal" uk-modal ref="modal_consultation">
          <div
            class="uk-modal-dialog uk-modal-body uk-width-1-1@s uk-width-1-4@m"
          >
            <h2 class="uk-modal-title uk-margin-remove ">
              Заявка на консультацию
            </h2>
            <button
              class="uk-modal-close-default"
              type="button"
              uk-close
            ></button>
            <form class="uk-margin-small-top">
              <div class="uk-grid uk-flex">
                <label
                  ><input
                    class="uk-radio uk-margin-small-right"
                    type="radio"
                    name="radio2"
                    value="fiz"
                    v-model="faceType"
                  />Физ. лицо</label
                >
                <label
                  ><input
                    class="uk-radio uk-margin-small-right"
                    type="radio"
                    name="radio2"
                    value="agent"
                    v-model="faceType"
                  />Агентство</label
                >
              </div>
              <template v-if="faceType == 'fiz'">
                <div>
                  <label class="uk-form-label" for="agent_name">ФИО</label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      :class="{ 'uk-form-danger': invalidName }"
                      v-model="name"
                      id="agent_name"
                      type="text"
                      placeholder="ФИО"
                    />
                  </div>
                </div>
                <div>
                  <label class="uk-form-label" for="agent_phone">Телефон</label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      :class="{ 'uk-form-danger': invalidPhone }"
                      v-model="phone"
                      id="agent_phone"
                      type="tel"
                      placeholder="(999) 999-99-99"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <h4 class="uk-margin-small-top">Агент</h4>
                <div class="uk-margin ">
                  <input
                    v-model="agency_name"
                    class="uk-input"
                    type="text"
                    placeholder="Наименование АН"
                  />
                </div>
                <div class="uk-margin ">
                  <input
                    v-model="agent_name"
                    class="uk-input"
                    type="text"
                    placeholder="ФИО"
                  />
                </div>
                <div class="uk-margin ">
                  <input
                    v-model="agent_phone"
                    name="phone"
                    class="uk-input"
                    type="text"
                    placeholder="+7 (999) 999-99-99"
                  />
                </div>

                <h4 class="uk-margin-small-top">Клиент</h4>
                <div class="uk-margin ">
                  <input
                    v-model="name"
                    class="uk-input"
                    type="text"
                    placeholder="ФИО"
                  />
                </div>
                <div class="uk-margin ">
                  <input
                    v-model="phone"
                    name="modal_phone"
                    class="uk-input"
                    type="text"
                    placeholder="+7 (999) 999-99-99"
                  />
                </div>
              </template>
            </form>
            <div v-if="isSend" class="uk-alert-success" uk-alert>
              <p>
                Заявка успешно отправлена. В ближайшее время с вами свяжется наш
                менеджер
              </p>
            </div>
            <p class="uk-text-center">
              <a class="uk-button uk-button-primary" @click="sendConsultation">
                Отправить заявку
              </a>
            </p>
          </div>
        </div>

        <div id="carousel-modal" class="uk-flex-top" uk-modal>
          <div
            class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body uk-width-2-3@m"
          >
            <button
              class="uk-modal-close-default"
              type="button"
              uk-close
            ></button>
            <div class="uk-position-relative uk-visible-toggle" uk-slider>
              <ul class="uk-slider-items uk-child-width-1-1">
                <li
                  v-for="(imgSrc, i) in selectedHouse
                    ? selectedHouse.img
                    : houses[0].img"
                  :key="i"
                >
                  <a :href="selectedHouse.url" target="_blank"
                    ><img
                      :src="
                        `https://vevanta.ru/wp-content/uploads/2020/houses/${imgSrc
                          .split('.')
                          .join('-full.')}`
                      "
                  /></a>
                </li>
              </ul>
              <a
                class="uk-position-center-left uk-position-small navigation"
                href="#"
                uk-slidenav-previous
                uk-slider-item="previous"
              ></a>
              <a
                class="uk-position-center-right uk-position-small navigation"
                href="#"
                uk-slidenav-next
                uk-slider-item="next"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dragscroll } from "vue-dragscroll";

//fontawesom icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRoad,
  faBurn,
  faWifi,
  faBolt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add([faRoad, faBurn, faWifi, faBolt]);

import input from '../filters/Input'

export default {
  mixins: [input],
  props: {
    village_id: {
      type: String,
      default: 1
    }
  },
  data() {
    return {
      faceType: "fiz",
      bgimage: "",
      phone: "",
      name: "",

      agency_name: "",
      agent_name: "",
      agent_phone: "",

      minPrice: 0,
      maxPrice: 4000000,
      minArea: 0,
      maxArea: 30,

      selectedPlace: "",
      statusFilter: "all",
      statuses: {
        1: "Свободен",
        2: "Забронирован",
        3: "Продан"
      },
      objects: [],
      houses: [
        {
          title: "Елена",
          img: ["elena-1.jpg", "elena-2.jpg", "elena-3.jpg", "elena-plan.jpg"],
          url: "https://vevanta.com/novye-proekty/proekt-doma-elena"
        },
        {
          title: "Тюменка",
          img: [
            "tyumenka-1.jpg",
            "tyumenka-2.jpg",
            "tyumenka-3.jpg",
            "tyumenka-plan.jpg"
          ],
          url: "https://vevanta.com/novye-proekty/proekt-doma-tjumenka"
        },
        {
          title: "Хай-Тек",
          img: [
            "hitek-1.jpg",
            "hitek-2.jpg",
            "hitek-3.jpg",
            "hitek-plan-1.jpg",
            "hitek-plan-2.jpg"
          ],
          url:
            "https://vevanta.com/novye-proekty/ultrasovremennyj-proekt-doma-haj-tek"
        },
        {
          title: "Даймонд",
          img: [
            "diamond-1.jpg",
            "diamond-2.jpg",
            "diamond-3.jpg",
            "diamond-plan.jpg"
          ],
          url: "https://vevanta.com/novye-proekty/proekt-dajmond"
        }
      ],
      publicPath: process.env.BASE_URL,
      selectedHouse: {
        title: "",
        img: [],
        url: ""
      },
      invalidName: false,
      invalidPhone: false,
      isSend: false,

      scale: 1
    };
  },
  watch: {
    agent_phone(val) {
      this.checkPhone(val, "agent_phone");
    },
    phone(val) {
      this.checkPhone(val, "phone");
    }
  },
  mounted() {
    this.$axios
      .post(`/developer/get_area/${this.village_id}`, {})
      .then(response => {
        this.bgimage = response.data.cottage_village.path_bg;
        this.objects = response.data.area;
      })
      .catch(error => {
        console.log(error);
      });
    this.selectedHouse = this.houses[0];
  },
  methods: {
    checkPhone(value, field) {
      if (value.startsWith("8")) this[field] = this[field].substring(1);
      else if (value.startsWith("+7")) this[field] = this[field].substring(2);
      this[field] = this[field].replace(/[\s-()]/gi, "");

      if (this[field].length > 10) {
        this[field] = this[field].slice(0, 10);
      }
    },
    sendConsultation() {
      this.$axios
        .post('https://life.workrf.ru/send.php', {
          face_type: this.faceType,
          name: this.name,
          phone: this.phone,
          agency_name: this.agency_name,
          agent_name: this.agent_name,
          agent_phone: this.agent_phone
        })
        .then(res => {
          this.name = "";
          this.phone = "";
          this.agency_name = "";
          this.agent_name = "";
          this.agent_phone = "";
          this.isSend = true;

          setTimeout(() => {
            this.isSend = false;
            UIkit.modal(this.$refs.modal_consultation).hide();
          }, 2000);
        })
        .catch(error => {
          console.log(error);
        });
    },
    reservationPlace(area_id, name_booking, phone_booking) {
      this.isValid();
      if (this.invalidName || this.invalidForm) return;
      this.$axios
        .post('https://life.workrf.ru/send.php', {'name':name_booking, 'phone':phone_booking})
        .then(res => {
          this.phone = "";
          this.name = "";
          this.isSend = true;

          setTimeout(() => {
            this.false = true;
            UIkit.modal(this.$refs.modal).hide();
          }, 2000);
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectClasses(object) {
      return {
        filter: this.filterPlace(object),
        selected: this.selectedPlace.id == object.id,
        free: object.status_id == 1,
        reserved: object.status_id == 2,
        saled: object.status_id == 3
      };
    },
    filterPlace(object) {
      return object.price >= this.minPrice &&
        object.price <= this.maxPrice &&
        object.land_area >= this.minArea &&
        object.land_area <= this.maxArea &&
        (this.statusFilter == "all"
        ? true
        : object.status_id == this.statusFilter);
    },
    isValid() {
      if (this.name == "") this.invalidName = true;
      if (this.phone.length < 10 || !parseInt(this.phone))
        this.invalidPhone = true;
      setTimeout(() => {
        this.invalidName = false;
        this.invalidPhone = false;
      }, 1000);
    },
    centroid(pointsStr) {
      let objectPoints = pointsStr.split(" ");
      let points = [];
      for (let i = 0; i < objectPoints.length - 1; i += 2) {
        points.push({
          x: objectPoints[i],
          y: objectPoints[i + 1]
        });
      }
      let l = points.length;
      return points.reduce(
        (center, p, i) => {
          center.x += parseFloat(p.x);
          center.y += parseFloat(p.y);
          if (i === l - 1) {
            center.x /= l;
            center.y /= l;
          }
          return center;
        },
        {
          x: 0,
          y: 0
        }
      );
    }
  },
  computed: {
    getLink() {
      return `https://pkk.rosreestr.ru/#/search/?type=1&inPoint=true&text=${this.selectedPlace.cadastral_number}&opened=${this.selectedPlace.cadastral_number}`;
    },
    getFilterObjects() {
      return this.objects.filter(object => !!object.points);
    },
    getAreaText() {
      if (
        Math.floor(this.selectedPlace.land_area) % 10 == 1 &&
        (Math.floor(this.selectedPlace.land_area) < 10 ||
          Math.floor(this.selectedPlace.land_area) > 20)
      )
        return "сотка";
      else if (
        Math.floor(this.selectedPlace.land_area) % 10 > 1 &&
        Math.floor(this.selectedPlace.land_area) % 10 < 5 &&
        (Math.floor(this.selectedPlace.land_area) < 10 ||
          Math.floor(this.selectedPlace.land_area)) > 20
      )
        return "сотки";
      else return "соток";
    },
    getBgPath() {
      return !!this.bgimage ? `https://cloud.vevanta.ru/${this.bgimage}` : "";
    }
  },
  components: {
    FontAwesomeIcon
  },
  directives: {
    dragscroll
  }
};
</script>

<style scoped>
.contain {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.stroke {
  margin-bottom: 20px !important;
}

.navigation {
  border-radius: 50%;
  color: #fff;
  background-color: rgba(234, 234, 234, 0.4);
}

.navigation:hover {
  opacity: 0.9;
}

.map {
  position: relative;
}

#wrapper {
  display: block;
  padding: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

#image {
  display: block;
  position: relative;
  height: 700px;
  width: 1200px;
  cursor: grab;
  transform-origin: 0% 0%;
  transition: 0.5s;
}

#svg {
  height: 100%;
  width: 100%;
  z-index: 2;
  display: inline-block;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

svg text {
  font-size: 10px;
}

polygon {
  stroke-width: 1;
  stroke: rgb(81 107 71 / 64%);
  fill-opacity: 0.1;
}

.svg-area:hover {
  cursor: pointer;
}

.svg-area:hover polygon {
  fill-opacity: 1;
  stroke: #fff;
}

circle {
  fill: #fffcc8;
}

polygon.free {
  fill: #79ff79;
}

polygon.saled {
  fill: #ff7d7d;
}

polygon.reserved {
  fill: #ffa14a;
}

polygon.filter {
  fill-opacity: 0.4;
}

polygon.selected {
  stroke: #ffffffe0;
  stroke-width: 2;
  fill-opacity: 1;
}

.unselected {
  fill-opacity: 0.3;
}

.area-land {
  font-size: 7px;
  position: relative;
}

.area-land:after {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
}

.reservation-card.uk-card {
  z-index: 100;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
}
.reservation-card.uk-card::-webkit-scrollbar {
  width: 8px;
}

.reservation-card.uk-card::-webkit-scrollbar-track {
  margin: 5px 0;
  border: none;
  background-color: #fff;
}

.reservation-card.uk-card::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.15);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.cart-info {
  background-color: #efefef;
  padding: 2px 5px;
  border-radius: 6px;
}

.cart-info.free {
  background-color: #d7ffd2;
}

.cart-info.saled {
  background-color: #ffe5e5;
}

.cart-info.reserved {
  background-color: #ffefc3;
}

.reservation-card .icon-info:hover {
  color: #04af04;
  overflow: auto;
}

.modal-button {
  margin: 10px 0px;
}
.modal-button:first-child {
  margin-right: 15px;
}

.place-params .uk-card {
  padding: 20px;
}

.scale-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.scale-button:first-child {
  top: 10px;
}
.scale-button:nth-child(2) {
  top: 60px;
}

@media screen and (max-width: 640px) {
  nav .uk-button {
    font-size: 13px;
    padding: 5px 0px;
  }
  .contain {
    width: 100%;
  }
}

.place-status .selected {
  background-color: #e2e2e2;
}

.place-status .uk-dropdown {
  padding: 0px;
}

.place-status li {
  padding: 10px 20px;
}

.place-status li:hover {
  cursor: pointer;
  background-color: #dedede;
}
</style>
