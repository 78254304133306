let form = {
  data() {
    return {
      api: {
        url: 'mail'
      },
      formName: '',
      msg: '',
      inputs: {
        phone: '',
        name: '',
      },
      dataRequest: {},
      isSent: false
    }
  },

  methods: {
    sendForm() {
      if (this.inputs.phone) {
        let msg = this.msg
        this.msg = `Форма: ${this.formName} <br>Имя: ${this.inputs.name} <br>Телефон: ${this.formatPhone} <br>Источник: ${location.href} <br>Комментарий: ${msg}`

        this.inputs.phone = this.formatPhone;

        let dataRequest = {
          ...this.inputs,
          ...this.dataRequest,
          form: this.formName,
          msg: this.msg,
          url: location.href,
        }


        this.$axios.post('https://life.workrf.ru/send.php', dataRequest)
        .then(() => {
          this.inputs.phone = ''
          this.inputs.name  = ''
          this.isSent = true
        })
      }
    },
  }
}

export default form
